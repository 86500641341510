import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./footer.scss";

import List2 from "../components/list2";

import Calendar from "../assets/svg/regular/calendar-alt.svg";
import Clock from "../assets/svg/regular/clock.svg";
import Smartphone from "../assets/svg/solid/mobile-alt.svg";
import Telephone from "../assets/svg/solid/phone-alt.svg";
import Mail from "../assets/svg/regular/envelope.svg";
import Pin from "../assets/svg/solid/map-marker-alt.svg";

import FacebookSVG from "../assets/svg/facebook.svg";
import TwitterSVG from "../assets/svg/twitter.svg";
import InstagramSVG from "../assets/svg/instagram.svg";
import LinkedinSVG from "../assets/svg/linkedin.svg";

import ISO9001 from "../images/badge/ISO-9001.png";
import ISO14001 from "../images/badge/ISO-14001.png";
import ISO45001 from "../images/badge/ISO-45001.png";
import PCAB from "../images/badge/pcab.png";
import PCA from "../images/badge/pca.png";

const Footer = ({ siteMetadata }) => {
  const { title, about, email, social, contact, address, opening, author, weekends } =
    siteMetadata;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer>
      <section className="footer-wrapper">
        <Container className="footer-content text-white">
          <Row className="section-md">
            <Col className="footer-aboutus" lg="4" sm="6">
              <h4 className=" text-center font-weight-bold text-uppercase mt-4 mb-2">
                Sobre Nós
              </h4>
              <p className="text-justify">{about}</p>
              <section className="footer-social">
                <div className="mb-4">
                  <a
                    href={social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookSVG className="fill-svg" />
                  </a>
                  <a
                    href={social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramSVG className="stroke-svg" />
                  </a>
                </div>
              </section>
              <h6 className="mt-6  text-center font-weight-bold text-uppercase">
                Horário de Atendimento
              </h6>
              <section className="opening ml-5">
                <hr />
                <List2
                  svgSize="lg"
                  svg={<Calendar />}
                  value={opening.day}
                />
                <hr />
                <List2
                  svgSize="lg"
                  svg={<Clock />}
                  value={opening.hour}
                />
                <hr />

                <List2
                    svgSize="lg"
                    svg={<Calendar />}
                    value={weekends.day}
                />
                <hr />
                <List2
                    svgSize="lg"
                    svg={<Clock />}
                    value={weekends.hour}
                />
                <hr />
              </section>
            </Col>
            <Col className="footer-contact" lg="4" sm="6">
              <h4 className="text-center font-weight-bold mt-4 mb-2 text-uppercase">
                Contato
              </h4>
              <ul className="list-unstyled">
                <li className="my-2">
                  <List2 svg={<Smartphone />} value={contact.mobile} />
                </li>
                <li className="my-2">
                  <List2 svg={<Telephone />} value={contact.telephone} />
                </li>
                <li className="my-2">
                  <List2 svg={<Mail />} value={email} />
                </li>
                <li className="my-2">
                  <List2 svg={<Pin />} value={address} />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="footer-credits justify-content-center">
          {` ${title} © ${currentYear} || Desenvolvido por: `} &nbsp;
          <a
            href={author.website}
            target="_blank"
            rel="noopener noreferrer"
            title={author.position}
          >
            {author.name}
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
