import React, { Component } from "react";
import { Link } from "gatsby";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
  Container,
} from "reactstrap";
import "./header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    const { siteTitle, logo } = this.props;
    return (
      <Navbar color="light" light expand="md" className="navbar fixed-top fw-bold">
        <Container className="ml-auto d-flex flex-row justify-content-between align-items-center">
          <Container>
            <NavbarBrand style={{ color: "transparent" }}
              to="/"
              className="navbar-brand align-items-center"
            >

              <img
                src={logo}
                alt={`${siteTitle} Logo`}
                height="60px"
                className="mr-2"
              />
              {siteTitle}
            </NavbarBrand>
          </Container>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: "#1551a1" }}
                  to="/"
                >
                  Inicio
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  style={{ color: "#1551a1" }}
                  activeStyle={{ fontWeight: "bold" }}
                  to="/servicos"
                >
                  Serviços
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  style={{ color: "#1551a1" }}
                  activeStyle={{ fontWeight: "bold" }}
                  to="/produtos"
                >
                  Produtos
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  style={{ color: "#1551a1" }}
                  activeStyle={{ fontWeight: "bold" }}
                  to="/sobre"
                >
                  Sobre
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  style={{ color: "#1551a1" }}
                  activeStyle={{ fontWeight: "bold" }}
                  to="/contato"
                >
                  Contato
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
