import React from "react";
import "./list.scss";

const List2 = ({
  svg,
  value,
  alignItems,
  className,
  alignSVG,
  svgSize,
  valueClass,
  label
}) => {
  return (
    <section
      className={`list  list-${svgSize || `md`
        } d-flex flex-nowrap gap-4 align-items-${alignItems || `start`} ${className || ""
        }`}
    >
      <div
        className={`svg mr-3 pt-1 h-100 d-flex align-items-${alignSVG || `start`
          }`}
      >
        {svg}
      </div>
      <div className={valueClass}>{label} {value || ""}</div>
    </section>
  );
};

export default List2;
